
import { Customer, useCustomerStore } from '@/stores/customer';
import { defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import BpCustomerEditorVue from '@/components/customer/BpCustomerEditor.vue';

export default defineComponent({
  name: 'customer-editor',
  components: {
    BpCustomerEditor: BpCustomerEditorVue
  },
  setup() {
    const router = useRouter();

    const store = useCustomerStore(true);

    const customer = ref<Customer>();

    function loadCustomer() {
      if (router.currentRoute.value.name && (router.currentRoute.value.name as string).endsWith('edit')) {
        const doc = store.getById(router.currentRoute.value.params.id as string)
        customer.value = doc;
      }
    }

    watch(() => store.isLoading(), () => {
      if (!store.loaded || store.isLoading()) { return }
      loadCustomer()
    }, { immediate: true });

    return {
      customer,
      store,
    }
  }
});
